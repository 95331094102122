import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 12; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/21-sep-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function AranyaRakshan() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          CLASS VI PRESENTATION - ARANYA RAKSHAK - JADHAV PAYENG
        </h1>

        <p>
          <strong>
            "TEACH YOUR CHILDREN TO LOVE NATURE, AND THE REST WILL HAPPEN ON ITS
            OWN"
          </strong>
          <br />
          <br />
          The students of class VI showcased a remarkable presentation to
          highlight the incredible power and determination of Jadhav Payeng, a
          popular Padma Shri Awardee, who transformed a barren land into a
          thriving Malai Forest. Through an informative skit and a mesmerising
          dance performance, they portrayed Jadhav Payeng's journey from sorrow
          and depression to becoming a forest crusader. The Principal of the
          school, Ms Archana Soni, emphasized the importance of holistic
          development of students and instilled a sense of responsibility
          towards nature, reminding us that nature nurtures us in return. This
          event showcased the values of hard work, determination, and patience,
          inspiring all to make positive changes in our environment.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
